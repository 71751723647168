import { requestPassword } from './Endpoints'
import axios from 'axios'

export default {
    async postRequestPassword(data) {
        const response = await axios.post(requestPassword.onPostRequest(), data);
        return response.data;
    },
    async postResetPassword(data) {
        const response = await axios.post(requestPassword.onPostReset(), data);
        return response.data;
    },
    async changePassword(data, config) {
        const response = await axios.put(requestPassword.onChangePassword(), data, config);
        return response.data;
    } 
}