import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import AssuranceMaladie from "../Services/AssuranceMaladie";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { updateItem } from "../store/Actions";
import { toast } from "react-toastify";
// import * as IconlyPack from 'react-iconly'

function EditIpmModal() {
  const { register, handleSubmit, formState, reset, setValue } = useForm();
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, ipm, ipms } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: "Veuillez vérifier le format du numéro de téléphone",
      },
    });
    setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (ipm?.id) {
      setPhone(ipm?.telephone);
      setValue("raison_sociale",ipm?.raison_sociale)
      setValue("specialite",ipm?.specialite)
      setValue("ninea",ipm?.ninea)
      setValue("email",ipm?.email)
      setValue("adresse",ipm?.adresse)
      setValue("telephone", ipm?.telephone);
    }
    // console.log(entreprise)
  }, [ipm?.id]);

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);
    if (!data?.email) {
      data.email = ipm.email;
    }

    if (!data?.raison_sociale) {
      data.raison_sociale = ipm.raison_sociale;
    }

    if (!data?.ninea) {
      data.ninea = ipm.ninea;
    }

    if (!data?.telephone) {
      data.telephone = ipm.telephone;
    }

    if (!data?.adresse) {
      data.adresse = ipm.adresse;
    }

    if (!data?.specialite) {
      data.specialite = ipm.specialite;
    }

    var ipmRequest = AssuranceMaladie.editAssuranceMaladie(
      ipm.id,
      data,
      requestConfig
    );

    await ipmRequest
      .then((response) => {
        var ipmInfos = response;
        console.log("ipm", ipmInfos);
        dispatch(updateItem(ipms, ipm.id, ipmInfos, "ADD_IPM"));
        setIsLoading(false);
        e.target.reset();
        window.$(".edit-ipm-modal").modal("hide");
        dispatch({ type: "EDIT_IPM", payload: {} });

        toast.success("Ipm modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const handleReset = async () => {
    reset();
    dispatch({ type: "EDIT_IPM", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddIPMModal">
            Modifier une IPM / ASSUREUR
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-ipm-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="name">Raison Sociale</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="raison_sociale"
                      defaultValue={ipm?.raison_sociale || ""}
                      {...register("raison_sociale")}
                      className="form-control crud-form-control"
                      id="name"
                    />
                    {formState.errors && formState.errors.raison_sociale && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.raison_sociale?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="specialite">Spécilalité</label>
                    <div className="filter-item-container">
                      {ipm?.specialite && (
                        <select
                          {...register("specialite")}
                          defaultValue={ipm?.specialite || ""}
                          name="specialite"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir une spécialité
                          </option>
                          <option value="ipm">IPM</option>
                          <option value="assureur">ASSURANCE SANTÉ</option>
                        </select>
                      )}

                      {formState.errors && formState.errors.specialite && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.specialite?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="ninea">NINEA</label>
                    <input
                      type="text"
                      placeholder="NINEA"
                      name="ninea"
                      {...register("ninea",{
                        required: {
                          value: true,
                          message: 'Le NINEA est requis'
                        },
                        minLength: {
                          value: 12,
                          message: 'Le ninea doit avoir au maximum 12 caractères'
                        },
                        maxLength: {
                          value: 12,
                          message: 'Le ninea doit avoir au maximum 12 caractères'
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/,
                          message: "Le NINEA doit être de format alpha-numérique.",
                        },
                      })}
                      defaultValue={ipm?.ninea || ""}
                      className="form-control crud-form-control"
                      id="ninea"
                    />
                    {formState.errors && formState.errors.ninea && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.ninea?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...register("email")}
                      defaultValue={ipm?.email || ""}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      defaultValue={ipm?.adresse || ""}
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    {/* <input
                      type="text"
                      placeholder="Téléphone"
                      name="telephone"
                      {...register('telephone')}
                      defaultValue={ipm?.telephone || ''}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Le numéro de téléphone doit être au format +221780158171
                      </span>
                    </div> */}
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Modifier
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIpmModal;
